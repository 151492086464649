.input-box {
  display: flex;
  border-radius: 4px;
  overflow: hidden;
  background: #f2f2f2;
  min-width: 240px;
}
.input-box.keysearch {
  margin-right: 24px;
  border-radius: 4px;
  border: 1px solid #999;
  background: #fff;
}
.input-box.keysearch form {
  flex-grow: 1;
}
/* .input-box.select {
    display: flex;
    border-radius: 4px;
    overflow: hidden;
    background: #F2F2F2;
    padding-right: 5px;
  } */
.input-box .form-control {
  width: 100%;
  border: 0;
  height: 36px;
  padding: 0 10px;
  outline: 0;
  background-color: #f2f2f2;
}
.input-field .input-box {
  border-radius: 0px;
}
.input-field #selectDropdown {
  border-bottom: 1px solid #B3B3B3;
  background: #ffffff;
  border-radius: 0;
  padding: 1px 2px;
  font-weight: normal;
}
.input-box .form-control::placeholder {
  color: #999;
}



.input-box .btn {
  background: #f2f2f2;
  /* border: 1px solid #000; */
  height: 36px;
  padding: 10px;
  margin-right: 6px;
  border: 0;
  cursor: pointer;
  outline: 0;
}



.input-box.keysearch .btn {
  background: #fff;
}
.input-box.keysearch .form-control {
  background-color: #fff;
  padding-left: 0px;
}
.input-box.keysearch .search-icon {
  padding: 10px 8px;
}
.input-box.keysearch .closeIcon {
  padding: 10px 8px;
  margin-right: 0px;
}
.input-box.keysearch .closeIcon img {
  width: 16px;
}

@media (min-width: 1024px) and (max-width:1500px) {
  .input-box {
    min-width: fit-content;
  }
}

@media (max-width: 1023px) {
  .input-box {
    min-width: auto;
  }
  .input-box.keysearch {
    margin-right: 0px;
  }
}
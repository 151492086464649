.errorCard {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  font-size: 16px;
  padding: 14px 18px;
  margin-bottom: 24px;
  width: 100%;
}

.link {
  color: #991B1B;
  font-weight: 700;
  text-decoration: none;
  font-size: 14px;
}

.link:hover {
  text-decoration: none;
}

.danger {
  background-color: #FEE2E2;
  border: 1px solid #FECACA;
  color: #991B1B;
}

.danger  .link {
  color: #991B1B;
}



.warning {
  background-color: #FEF3C7;
  border: 1px solid #FDE68A;
  color: #92400E;
}
.warning  .link {
  color: #92400E; 
}



.success {
  background: #D1FAE5;
  border: 1px solid #A7F3D0;
  border-radius: 8px;
  color: #065F46;
}
.success  .link {
  color: #065F46; 
}
.info {
  background: #CBF1FF;
  border: 1px solid #BBDEEB;
  border-radius: 8px;
  color: #00749E;
}
.info  .link {
  color: #00749E; 
}



.errorMsg {
  margin-right: 8px;
}

.errorCardInner {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.icon {
  width: 32px;
  height: 32px;
  margin-right: 20px;
}

.icon img {
  max-width: 100%;
}

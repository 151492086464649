.flex {
    padding: 1.5rem;
    display: flex;
    justify-content: space-between;
    height: 100%;
    flex-wrap: wrap;
}
.flex > div {
    width: 100%;
    align-items: flex-end;
}
.row{
    display: flex;
}

.mb30{
    margin-bottom: 30px;
}
.mb26{
    margin-bottom: 26px;
}
.mb20{
    margin-bottom: 20px;
}
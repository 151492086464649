.row{
    margin-bottom: 16px;
}
.row p{
    font-size: 14px;
}
.title{
    font-size: 20px;
    font-weight: bold;
    line-height: 1.4;
}
.mb26{
    margin-bottom: 26px;
}
.badgeLabel{
    font-size: 14px;
    margin-bottom: 10px;
    font-weight: bold;
    color: #000;;
}
.badge{
    background-color: #DC6914;
    border-radius: 35px;
    font-size: 14px;
    font-weight: bold;
    color: #fff;
    padding: 2px 16px;
    line-height: 1.4;
    display: inline-block;
}

.badgeNotPerformed{
    background-color: #006487;
    border-radius: 35px;
    font-size: 14px;
    font-weight: bold;
    color: #fff;
    padding: 2px 16px;
    line-height: 1.4;
    display: inline-block;
}
.badgePartiallyPerformed{
    background-color: #DC6914;
    border-radius: 35px;
    font-size: 14px;
    font-weight: bold;
    color: #fff;
    padding: 2px 16px;
    line-height: 1.4;
    display: inline-block;
}
.badgeCompleted{
    background-color: #647D2D;
    border-radius: 35px;
    font-size: 14px;
    font-weight: bold;
    color: #fff;
    padding: 2px 16px;
    line-height: 1.4;
    display: inline-block;
}

.label{
    font-size: 14px;
    margin-bottom: 10px;
    font-weight: bold;
    color: #000;;
}
.details{
    font-size: 14px;
    margin-bottom: 10px;
    color: #000;;
}
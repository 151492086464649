

.ModalContainer {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 32px;
    gap: 32px;
    position: fixed;
    background: #FFFFFF;
    box-shadow: 0px 25px 50px -12px rgba(0, 0, 0, 0.1);
    border-radius: 16px;
    z-index: 3;
}

.ModalHeader {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 0px 0px 16px;
    gap: 48px;
    background: #FFFFFF;
    flex: none;
    order: 0;
    align-self: stretch;
    flex-grow: 0;
}

.HeadingText {
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 140%;
    color: #000000;
    flex: none;
    order: 0;
    flex-grow: 1;
}

.CloseIcon {
    width: 24px;
    height: 24px;
    flex: none;
    order: 1;
    flex-grow: 0;
    cursor:pointer;
}

.Backdrop {
    position: fixed;
    left: 0%;
    right: 0%;
    top: 0%;
    bottom: 0%;
    background: #223F4B;
    opacity: 0.4;
    z-index: 2;
}

.ModalFooter {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 24px 0px 0px;
    background: #FFFFFF;
    flex: none;
    order: 4;
    align-self: stretch;
    flex-grow: 0;
}

.IsMobile.ModalContainer {
    padding:16px;
}
.IsMobile .ModalHeader {
    gap:8px;
}
.IsMobile .HeadingText {
    flex-basis:88%;
}
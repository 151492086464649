.select_options {
    gap:3px;
    padding:16px;
    background:#fff;
    border-radius:4px;
    z-index: 1;
}
.select_options > div {
    padding: 4px;
    cursor:pointer;
    padding:8px 16px;
    border-radius:4px;
}
.select_options > div:hover {
    background-color:#f2f2f2;
}
.pagination-left-arrow {
    filter: var(--svgFilterGrey);
    transform: rotateY(180deg);
    cursor: pointer;
  }
  .pagination-right-arrow {
    filter: var(--svgFilterGrey);
    cursor: pointer;
  }
.dropzone{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /* padding: 24px; */
    gap: 16px;

    width: 100%;
    height: 100%;
    height: 30vh;
    overflow-y: auto;
    cursor: pointer;

    background: rgba(0, 116, 158, 0.1);
    /* Primary / DK Blue Gray 1 */

    border: 1px dashed #567783;
    border-radius: 4px;
}

.dropzone embed,
.dropzone iframe {
    height:30vh;
    width: 100%;/* 
    overflow:hidden;
    pointer-events:none; */
    border: 0;
}

.form_field_row {
    display: flex;
    align-items: center;
}
.input {
    border: none;
    height: 32px;
    border-bottom: 1px solid #B3B3B3;
    flex-grow: 1;
    font-size: 16px;
    width: 100%;
    resize: vertical;
}
.input:focus-visible {
    outline: none;
}
.cursor_pointer {
    cursor: pointer;
}
.tooltip_icon {
    margin-left: 5px;
    height: 16px;
    width: 16px;
}
.error_text {
    color: red;
}
.input_label {
    flex-basis: 30%;
    font-size: 16px;
    height: 32px;
    display: flex;
    align-items: center;
}
.form_group {
    display: flex;
    flex-wrap: wrap;
    flex-grow: 1;
    margin-bottom: 15px;
    position: relative;
}
.deleteFile {
    position: absolute;
    right: 0;
    cursor: pointer;
}

.errorField {
    background-color: #FEE2E2;
    color: #991B1B;
}
.error_text {
    color: #991B1B;
}
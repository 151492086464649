@font-face {
  font-family: 'DIN Next LT Pro';
  src: url('../../assets/fonts/DINNextLTPro-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
  font-display: block;
}

@font-face {
  font-family: 'DIN Next LT Pro';
  src: url('../../assets/fonts/DINNextLTPro-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
  font-display: block;
}

@font-face {
  font-family: 'DIN Next LT Pro';
  src: url('../../assets/fonts/DINNextLTPro-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}


* {
  box-sizing: border-box;
  font-family: 'DIN Next LT Pro';
  line-height: 1.4;
}


body {
  margin: 0;
  font-family: "DIN Next LT Pro";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

:root {
  --primaryGrey: #e6ecee;
  --primaryGreyFont: #666666;
  --secondaryGrey: #808080;
  --disabledButtonBg: #CCCCCC;
  --disabledButtonFont: #808080;
  --fs40px: 2.5rem;
  --fs28px: 1.75rem;
  --fs24px: 1.5rem;
  --fs20px: 1.25rem;
  --fs16px: 1rem;
  --fs14px: 0.875rem;
  --fs12px: 0.75rem;
  --darkBlueButton: #3c5d69;
  --svgFilterDarkBlueButton: invert(31%) sepia(21%) saturate(786%) hue-rotate(150deg) brightness(94%) contrast(83%);
  --svgFilterLightBlueButton: invert(48%) sepia(12%) saturate(961%) hue-rotate(150deg) brightness(89%) contrast(86%);
  --svgFilterOrangeButton: invert(17%) sepia(49%) saturate(6751%) hue-rotate(37deg) brightness(91%) contrast(89%);
  --svgFilterGrey: invert(85%) sepia(0%) saturate(1%) hue-rotate(164deg) brightness(87%) contrast(82%);
  --svgFilterWhite: invert(100%) sepia(100%) saturate(2%) hue-rotate(357deg) brightness(106%) contrast(101%);
  --svgFilter567783: invert(44%) sepia(5%) saturate(2107%) hue-rotate(150deg) brightness(97%) contrast(83%);
  --greyButton: #cdd9dd;
  --toastify-color-error: #FEE2E2 !important;
  --toastify-text-color-error: #991B1B !important;
  --redButton: #E31F26;
  --toastify-toast-width: 80%;
  --toastify-color-progress-warning: var( --toastify-text-color-error) !important;
}

.Toastify__close-button {
  color: #040404;
}
.Toastify__toast-theme--colored.Toastify__toast--error {
  color: #991b1b;
  background: #fee2e2;
}
.Toastify__progress-bar-theme--colored.Toastify__progress-bar--info, .Toastify__progress-bar-theme--colored.Toastify__progress-bar--success, .Toastify__progress-bar-theme--colored.Toastify__progress-bar--warning, .Toastify__progress-bar-theme--colored.Toastify__progress-bar--error {

  background: #fdb8b8;
}
/* width */
.container::-webkit-scrollbar {
  /* width: 10px; */
  display: none;
}

h1,
h2,
h3,
h4 {
  margin-top: 0;
  margin-bottom: 0;
  display: block;
}
h1{
  font-size: var(--fs40px);
}
h2{
  font-size: var(--fs28px);
}
h3{
  font-size: var(--fs24px);
}
h4{
  font-size: var(--fs20px);
}
.font14 {
  font-size: 0.875rem;
  line-height: 19.5px;
}
.font12 {
  font-size: 0.75rem;
}

.inline-flex {
  display: inline-flex;
}

.flex-grow-1 {
  flex-grow: 1 !important;
}
.flex-grow-0 {
  flex-grow: 0 !important;
}
.flex-start {
  justify-content: flex-start !important;
}
.flex-end {
  justify-content: flex-end;
}
.flex-between {
  justify-content: space-between;
}
.tooltip-icon {
  margin-left: 5px;
  height: 16px;
  width: 16px;
}
.cursor-pointer {
  cursor: pointer;
}



body.noScroll{
  overflow: hidden;
}
.mwidth-206 {
  max-width: 206px;
}

.marginBot8 {
  margin-bottom: 8px;
} 

.marginBot32 {
  margin-bottom: 32px;
} 
.marginBot40 {
  margin-bottom: 40px;
} 

.marginBot30 {
  margin-bottom: 30px;
}
.marginBot52 {
  margin-bottom: 52px;
}

.marginBot24 {
  margin-bottom: 24px;
}

.marginRight48 {
  margin-right: 48px;
}
.marginRight36 {
  margin-right: 36px;
}
.marginLeft21 {
  margin-left: 21px !important;
}
.marginLeft16 {
  margin-left: 16px;
}
.marginLeft15 {
  margin-left: 15px !important;
}

.height36 {
  height: 36px;
}

.flex {
  display: flex;
}
.align-baseline {
  align-items: baseline;
}
.align-end {
  align-items: flex-end;
}
.align-center {
  align-items: center;
}

.tab-item {
  padding-bottom: 4px;
  margin-bottom: 10px;
  font-size: 20px;
  font-weight: bold;
  color: #808080;
  cursor: pointer;
  border-bottom: solid var(--primaryGrey) 2px;
  position: relative;
}

.tab-item.active-tab {
  border-bottom: solid #3C5D69 2px;
  color: black;
  transition: border-bottom 150ms ease;
}

.path {
  font-size: 14px;
}

.link_text {
  text-decoration: none;
  color: #3C5D69;
  font-weight: bold;
}

.table_text {
  color: #567783;
  text-decoration: none;
  font-weight: bold;
  margin-left: 6px;
  font-size: 14px;
}

.pencil {
  cursor: pointer;
}

p {
  display: block;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  
}
p:after{
  content:"";
  display:table;
  width:100%;
}


.react-datepicker__day--selected, .react-datepicker__day--in-selecting-range, .react-datepicker__day--in-range, .react-datepicker__month-text--selected, .react-datepicker__month-text--in-selecting-range, .react-datepicker__month-text--in-range, .react-datepicker__quarter-text--selected, .react-datepicker__quarter-text--in-selecting-range, .react-datepicker__quarter-text--in-range, .react-datepicker__year-text--selected, .react-datepicker__year-text--in-selecting-range, .react-datepicker__year-text--in-range {
  border-radius: 0.3rem;
  background-color: #3c5d69;
  color: #fff;
}

input[type="checkbox"] {
	accent-color: #3C5D69;
}

.centeredText {
	text-align: center;
}

.input:focus-visible{
  outline: none;
}

/* Set the default style for inputs. mainly giving the input a solid border */
.input {
  /* Shades/White */    
  background: #FFFFFF;
  /* Neutral/Gray 40 */    
  border: 1px solid #999999;
}
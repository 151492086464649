.tooltip_menu {
    position: absolute;
    box-shadow: 0px 25px 50px -12px rgba(0, 0, 0, 0.2);
    border-radius: 0px 8px 8px 8px;
    background: white;
    display: flex;
    z-index: 2;
    padding: 10px;
  }

.backdrop {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100vh;
	z-index: 1;
	/* background-color: rgba(0,0,0,0.4); */
}
.checkmark {
    height: 16px;
    width: 16px;
    border-radius: 3px;
    border: 1px solid #999999;
    background-color: white;
    position:relative;
}
.checkmark:hover {
    background-color: #ccc;
}

.checkbox input:checked ~ .checkmark {
    background-color: white;
    border: 0.666667px solid #3C5D69;
}
.checkmark:after {
    content: "";
    position: absolute;
    display: none;
}

.checkbox input:checked ~ .checkmark:after {
    display: flex;
}
.checkbox .checkmark:after {
    left: 1px;
    top: 1px;
    width: 12px;
    height: 12px;
    background-color: #3C5D69;
    border-radius: 2px;
}
.checkmark_container {
    flex-basis:57px;
    align-items:center;
}
.checkbox {
    display:flex;
    gap:8px;
    align-items: center;
}
.checkbox input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}
.checkmark,
.label {
    cursor:pointer;
    text-transform: capitalize;
}
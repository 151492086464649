.form_field_row{
  display: flex;
  align-items: flex-start;
  margin-bottom: 30px;
}
.input {
  border: none;
  height: 32px;
  border-bottom: 1px solid #B3B3B3;
  flex-grow: 1;
  font-size: 16px;
  width: 100%;
  resize: vertical;
}
.input::-webkit-scrollbar {
  /* width: 10px; */
  display: inline;
}
.input:focus-visible{
  outline: none;
}
input[type="text"]::-webkit-input-placeholder { /* Edge */
  color: #999;
}

input[type="text"]:-ms-input-placeholder { /* Internet Explorer 10-11 */
  color: #999;
}

input[type="text"]::placeholder {
  color: #999;
}

.cursor_pointer{
  cursor: pointer;
}
.tooltip_icon {
  margin-left: 5px;
  height: 16px;
  width: 16px;
}
.tooltip_icon_pointer {
  margin-left: 5px;
  height: 16px;
  width: 16px;
  cursor: pointer;
}
.error_text{
  color: red;
}
.input_label {
  flex-basis: 30%;
  font-size: 16px;
  height: 32px;
  display: flex;
  align-items: flex-start;
  padding-top: 2px;
}
.form_group{
  display: flex;
  flex-wrap: wrap;
  flex-grow: 1;
}
.errorField{
  background-color: #FEE2E2;
  color: #991B1B;
}
.error_text{
  color: #991B1B;
}
@media screen and (max-width: 767px) {

  .input{   
    border: 1px solid #999;
    flex-grow: 1;
    font-size: 16px;
    width: 100%;
    border-radius: 4px;
    padding:10px 16px;
  }
  .input_box {
    width: 100%;
  }
  .form_field_row {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }
  .input_label {
    -ms-flex-preferred-size: 100%;
    flex-basis: 100%;
  }
}
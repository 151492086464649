.navbar {
  display: flex;
  flex-flow: row nowrap;
  flex-grow: 1;
}

.nav-item {
  display: flex;
  flex-grow: 1;
  justify-content: flex-start;
  flex-basis: auto;
  align-items: center;
}

.nav-item:nth-child(2) {
  justify-content: flex-start;
}

.nav-item:last-child {
  justify-content: flex-end;
}

.App-logo {
  pointer-events: none;
  height: 10vmin;
  width: 15vw;
  max-width: 225px;
  margin-left: 24px;
}


.login-details {
  justify-content: flex-end;
}
.initials {
  border-radius: 24px;
  background-color: transparent;
  color: #ffffff;
  font-weight: bold;
  padding: 0;
  font-size: 16px;
  margin-right: 8px;
  width: 40px;
  overflow: hidden;
  height: 40px;
 
}
.initials img{
  max-width: 100%;
}
.name {
  font-weight: bold;
  font-size: 14px;
  margin-bottom: 4px;
}
.position {
  font-size: 14px;
}
.bell-icon {
  margin-right: 24px;
}
.nav__action{  
  max-width: 40px;
}
.nav__action:last-child{
  margin-left: 16px;
}
.nav__action_wrp{
  display: flex;
  align-items: center;

}

.sidebar-mobile-wrapper .input-box .search__icon {
    max-height: 16px;
}

.sidebar-mobile-wrapper .input-box .close__icon {
  max-height: 16px;
  padding: 0 10px;
}

.sidebar-mobile-wrapper .input-box .close__icon img {
  width: 16px;
}

@media screen and (max-width:1023px){
  .sidebar-mobile-wrapper .input-box .search__icon{
    padding: 0 10px;
  }
  .sidebar-mobile-wrapper .input-box{
    align-items: center;
  }
  .mobile-nav-item{
    font-size: 14px;
  }
  .navbar {    
    border-bottom: 1px solid #CDD9DD;
  
}
}

@media screen and (max-width:767px){
  .App-logo {    
    height: 82px;
    width: 123px; 
    margin-left: 0; 
}
}
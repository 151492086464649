#selectDropdown {
  display: flex;
  border-radius: 4px;
  overflow: hidden;
  background: #f2f2f2;
  padding-right: 5px;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
}

.request_type #selectDropdown{
  height:32px;
}

.input-field #selectDropdown {
  font-size: 14px;
}

.nameplate-dropdown #selectDropdown {
  font-size: 14px;
  background: white;
  border: 1px solid #999999;
  font-weight: normal;
}
.nameplate-dropdown.disabled #selectDropdown {
  background: #f2f2f2;
  border: 1px solid #f2f2f2;
}

.nameplate-dropdown .options {
  font-weight: normal;
}

.only-bottom-border-dropdown  #selectDropdown {
	border: none;
	border-bottom: 1px solid #999999;
	border-radius: 0%;
}

.selectDropdownWrp {
  position: relative;
}
.input-field .selectDropdownWrp {
  flex-grow: 1;
}

.input-box .form-control.selectbox {
  font-weight: bold;
}

.select-option {
  font-weight: bold;
  padding: 12px;
}

.img-wrapper {
  height: 24px;
}

.select-arrow {
  transform: rotateX(0);
  transition: transform 350ms ease-out;
  margin-left: auto;
}
.select-arrow.active {
  transform: rotateX(180deg);
  transition: transform 350ms ease-out;
}

.placeholder {
  color: #999999;
}

.input-box.dropdown {
  position: absolute;
  margin-top: 15px;
  max-height: 0;
  transition: max-height 250ms ease-out;
  min-width: 239px;
  background-color: #ffffff;
  z-index: 2;
  overflow-y: auto;
  box-shadow: 0px 25px 50px -12px rgba(0, 0, 0, 0.1);
}

.input-box.dropdown.active {
  max-height: 200px;
  transition: max-height 250ms ease-in;
  background-color: #ffffff;
  height: auto;
}

.input-field .input-box.dropdown {
  width: 100%;
}

.options-wrapper {
  padding: 16px;
  width: 100%;
}
.options {
  padding: 8px 16px;
  font-weight: bold;
  font-size: 14px;
  border-radius: 4px;
  cursor: pointer;
}
.options:hover {
  background-color: var(--primaryGrey);
}
.input-field .options {
  font-weight: normal;
}

.borderDropdown #selectDropdown {
  font-size: 14px;
  background: white;
  border: 1px solid #999999;
  font-weight: normal;
}
.access-dropdown .input-box {
  margin: 1px 0 !important;
}
@media screen and (max-width: 1200px) {
  .borderDropdown .input-box,
  .borderDropdown #selectDropdown {
    height: 48px;
    margin: 0;
  }
}
@media screen and (max-width: 1023px) {
  .mobile-dropdown.selectDropdownWrp {
    position: absolute;
    top: 86px;
    background: #fff;
    right: 24px;
    left: 24px;
    transform: translateY(0%);
    z-index: -1;
    transition: transform 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  }
  .selectDropdownWrp.active {
    transform: translateY(-100%);
    transition: transform 1s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  }
  .input-box.dropdown {
    width: 100%;
    top: 100%;
    z-index: 3;
  }
  .unitDropdown .input-box {
    margin: 0px 0;
  }
}
@media screen and (max-width: 767px) {
  .mobile-dropdown.selectDropdownWrp {
    right: 16px;
    left: 16px;
  }
}

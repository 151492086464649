.DatePickerCalendar__row {
    display: flex;
}

.DatePickerCalendar__header {
    margin-bottom: 8px;
    display: flex;
}

.DatePickerCalendar__cell {
    padding: 4px;
    width: 38px;
    height: 38px;
    font-weight: 700;
    margin: 0 2px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.DatePickerCalendar__dayCell {
    cursor: pointer;
    font-weight: 400;
}

.DatePickerCalendar__dayCell:not(.DatePickerCalendar__dayCell_selected):hover {
    background-color: #e9e9e9;
    border-radius: 2px;
}

.DatePickerCalendar__dayCell:active {
    background-color: #d1d1d1;
}


.DatePickerCalendar__dayCell_selected {
    background: #CDD9DD;
    border-radius: 2px;
}
.flex {
    padding: 1.5rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
height: 100%;
}
.inlineflex{
    display: inline-flex;
    align-items: center;
}
.row{
    display: flex;
}
.iconwrapper{   
    width: 24px;
    height: 24px;   
    cursor: pointer;
    margin-right: 8px;
}

.mb30{
    margin-bottom: 30px;
}
.mb26{
    margin-bottom: 26px;
}
.mb20{
    margin-bottom: 20px;
}

.publishedLink{
    display: flex;
    flex-direction: column;  
   
}

.header{
    display: flex;
    justify-content: space-between;
    font-size: 14px;
    align-items: center;
    font-weight: bold;
}
.title{
    text-align: left;
    line-height: 20px;
}
.email a{
    color:#00749E
}
.navbar {
  display: flex;
  flex-flow: row nowrap;
  flex-grow: 1;
}

.nav-item {
  display: flex;
  flex-grow: 1;
  justify-content: flex-start;
  flex-basis: auto;
  align-items: center;
}

.nav-item:nth-child(2) {
  justify-content: flex-start;
}

.nav-item:last-child {
  justify-content: flex-end;
}

.App-logo {
  pointer-events: none;
  height: 10vmin;
  width: 15vw;
  margin-left: 24px;
}


.login-details {
  justify-content: flex-end;
}

.name {
  font-weight: bold;
  font-size: 14px;
  margin-bottom: 4px;
}
.position {
  font-size: 14px;
}
.bell-icon {
  margin-right: 24px;
}
.nav__action{  
  max-width: 40px;
}
.nav__action:last-child{
  margin-left: 16px;
}
.nav__action_wrp{
  display: flex;
  align-items: center;

}

@media screen and (max-width:1023px){
  .navbar {    
    border-bottom: 1px solid #CDD9DD;
    background: #fff;
}
}

@media screen and (max-width:767px){
  .App-logo {    
    height: 82px;
    width: 123px; 
    margin-left: 0; 
}
}
.filter_pill_container {
  display:flex;
  flex-direction:row;
  gap:24px;
  flex-wrap: wrap;
}
.filter_pill {
  padding:4px 12px;
  color:#000;
  background:#CDD9DD;
  font-weight:normal;
  display:flex;
  flex-direction:row;
  gap:10px;
  align-items:center;
  white-space: nowrap;
}
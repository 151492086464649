.relatedLinks {
  display: flex;
  flex-flow: column;
  margin: 48px;
  margin-top: 20px;
}

.relatedLinks_header {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 10px;
  color: #3C5D69;
}

.relatedLinks_content {
  display: flex;
  justify-content: space-between;
}

.relatedLinks_item {
  margin-bottom: 32px;
}

.relatedLinks_title {
  color: var(--darkBlueButton);
  font-size: 20px;
  font-weight: bold;
}
.darkLinks_title {
  color: var(--darkBlueButton);
  font-size: 14px;
  font-weight: bold;
}
.relatedLinks_paragraph {
  font-size: 14px;
  margin: 12px 0 24px 0;
}
@media screen and (max-width:1023px){
.relatedLinks_item {
  flex-basis: 30%;
  max-width: 300px;
}
}
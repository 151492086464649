.flex {
    padding: 1.5rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
}
.row{
    display: flex;
}

.mb30{
    margin-bottom: 30px;
}
.mb26{
    margin-bottom: 26px;
}
.mb20{
    margin-bottom: 20px;
}

.contact{
    display: flex;
    flex-direction: column;
    border-bottom: 1px solid #e6e6e6;
    padding: 16px 0;
}
.contactsWrp{
    padding: 0 15px;
}
.header{
    display: flex;
    justify-content: space-between;
    font-size: 14px;
}
.email{
    text-align: left;
}
.email a{
    color:#00749E
}
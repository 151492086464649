.tooltip-actions {
    position: absolute;
    top: 22px;
    box-shadow: 0px 25px 50px -12px rgba(0, 0, 0, 0.1);
    border-radius: 0px 8px 8px 8px;
    background: white;
    display: flex;
    flex-flow: column;
    z-index: 2;
    padding: 24px;
    gap:24px;
    font-weight:bold;
  }
  .tooltip-actions .tooltip-action-item {
    position: relative;
    display:flex;
    flex-direction:row;
    gap:16px;
    align-items:center;
    padding:0 10px;
    white-space: nowrap;
    cursor:pointer;
    color:var(--darkBlueButton);
  }
  .tooltip-actions .tooltip-action-item:hover {
    color:#223F48;
    text-decoration: underline;
  }
  
  .tooltip-actions .tooltip-action-item img {
    height:24px;
    width:24px;
    filter: var(--svgFilterDarkBlueButton);
  }
  .tooltip-actions .tooltip-action-item:hover img {
    filter: invert(20%) sepia(8%) saturate(2980%) hue-rotate(153deg) brightness(90%) contrast(87%);
  }
  @media screen and (max-width:1024px){
  .tooltip-actions {
    /* right: unset!important; */
    top: unset;
    margin-top: -100px;
    margin-left: 60px;
  }
}
.form_field_row{
  display: flex;
  align-items: center;
  margin-bottom: 30px;
}

.radiobtnname{
  text-transform: capitalize;
}
.input {
  border: none;
  height: 32px;
  border-bottom: 1px solid #B3B3B3;
  flex-grow: 1;
  font-size: 16px;
  width: 100%;
}
.input:focus-visible{
  outline: none;
}
.cursor_pointer{
  cursor: pointer;
}
.tooltip_icon {
  margin-left: 5px;
  height: 16px;
  width: 16px;
}
.error_text{
  color: red;
}
.input_label {
  flex-basis: 30%;
  font-size: 16px;
  height: 32px;
  display: flex;
  align-items: center;
}
.form_group{
  display: flex;
  flex-wrap: wrap;
  flex-grow: 1;
}
.errorField{
  background-color: #FEE2E2;
  color: #991B1B;
}
.error_text{
  color: #991B1B;
}

@media screen and (max-width:767px){
  .form_field_row{   
    flex-wrap: wrap;    
  }
  .input_label {
    flex-basis: 100%;
    height: auto;
  }
  .input{
    height: 48px;
    border: 1px solid #999;
    flex-grow: 1;
    font-size: 16px;
    width: 100%;
    border-radius: 4px;
    padding-left: 16px;
    padding-right: 16px;
  }

}
.radioButtonGroup {
  display:flex;
  flex-direction:column;
  gap:8px;
}
.radioButtonGroup label {
  display:flex;
  flex-direction:row;
  gap: 8px;
}
.radioButtonGroup input {
  margin:0;
}
.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 20;
  background-color: rgba(0,0,0,0.4);
}

.close_modal {
  color: #aaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
  cursor: pointer;
}

.modalTitle {
  font-weight: bold;
  font-size: 20px;
  margin-bottom: 40px;
  display: flex;
  align-items: center;
}

.flexGrow1 {
  flex-grow: 1 !important;
  text-align: center;
}
.flexGrow2 {
  flex-grow: 1 !important;
  text-align: left;
}

.modal {
  position: fixed;
  width: 90%;
  max-width: 700px;
  border-radius: 14px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.25);
  z-index: 30;
  /* animation: slide-down 300ms ease-out forwards; */
  left: 50%;
  top:50%;
  transform: translate(-50%,-50%);
  background-color: #fefefe;
  border-radius: 16px;
  padding: 32px;
  border: 1px solid #888;
  max-height: calc(100% - 1em);
  overflow-y: auto; 
}

@keyframes slide-down {
  from {
    opacity: 0;
    top:-3rem
  }
  to {
    opacity: 1;
    top:0;
  }
}

@media screen and (max-width:767px){
  .modal{
    padding: 24px;
  }
}
.flex {
    padding: 1.5rem;
    display: flex;
    flex-direction: column;
    height: 100%;
}

.header {
    justify-content: space-between;
    align-items: center;
    display: flex;
}
.mb30{
    margin-bottom: 30px;
}
.mb24{
    margin-bottom: 24px;
}
.mb8{
    margin-bottom: 8px;
}
.icon1{
    height: 40px;
}
.row {
    display: flex;
}
.row:last-child {
    align-items: flex-end;
    flex-grow: 1;
}
.p{
    margin: 0;

}
.title{
    text-transform: capitalize;
}
.date{
    color:#808080;
    font-size: 14px;
}
.desc{
    line-height: 20px;
    font-size: 14px;
    margin: 0;
}
.Announcement_TextArea > div > label > div {
    width: min-content;
}